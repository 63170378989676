define("ember-simple-auth-token/mixins/token-authorizer", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object", "@ember/utils", "ember-simple-auth/mixins/data-adapter-mixin", "ember-get-config"], function (_exports, _mixin, _service, _object, _utils, _dataAdapterMixin, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Authorizer Mixin that works with token-based authentication like JWT by sending the `token` properties from the session in the `Authorization` header.
  
    @class TokenAuthorizer
    @module ember-simple-auth-token/mixins/token-authorizer
    @extends Ember.Mixin
  */
  var _default = _exports.default = _mixin.default.create(_dataAdapterMixin.default, {
    session: (0, _service.inject)('session'),
    /**
      @method init
    */
    init() {
      this._super(...arguments);
      const conf = _emberGetConfig.default['ember-simple-auth-token'] || {};
      this.tokenPropertyName = conf.tokenPropertyName || 'token';
      this.authorizationHeaderName = conf.authorizationHeaderName || 'Authorization';
      this.authorizationPrefix = conf.authorizationPrefix === '' ? '' : conf.authorizationPrefix || 'Bearer ';
    },
    /**
      Authorizes an XHR request by sending the `token` properties from the session in the `Authorization` header:
       ```
      Authorization: Bearer <token>
      ```
       @method authorize
      @param {XMLHttpRequest} xhr
    */
    authorize(xhr) {
      const data = (0, _object.get)(this, 'session.data.authenticated');
      const token = (0, _object.get)(data, this.get('tokenPropertyName'));
      const prefix = this.get('authorizationPrefix');
      const header = this.get('authorizationHeaderName');
      if (this.get('session.isAuthenticated') && !(0, _utils.isEmpty)(token)) {
        xhr.setRequestHeader(header, prefix + token);
      }
    }
  });
});